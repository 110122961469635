import Header from "../Header";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";

var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Savingcustomer({ hadlersuper }) {
  const [data, setData] = useState([]);

  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);
  const [NumberOfsavings, setNuberOfSavings] = useState("");
  const [TypeOfsavings, setTypeOfsavings] = useState("");
  const [savingsHistory, setsavingsHistory] = useState([]);
  const [VoluntaryToMandatoryApiData, setVoluntaryToMandatoryApiData] =
    useState([]);
  const [interestData, setInterstSaving] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [page, setpage] = useState("");
  const [SIpage, setSIpage] = useState("");
  const [formData, handleInputChange] = useState({
    amount: "",
  });
  const [custID, setcustID] = useState("");
  const [WithtypeOfsavings, setWithTypeOfsavings] = useState("mandatory");
  const [withAmount, setWithAmount] = useState("");
  const [Delethandler, setDelethandler] = useState(false);
  
  const [withdrawtabfilter, setwithdrawtabfilter] = useState("all");
  const [intrestfilterawdata, setintrestfilterawdata] = useState("mandatory");

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  console.log({ WithtypeOfsavings });
  let token = localStorage.getItem("SuperAgentChromepayToken");
  let { _id } = useParams();

  const AddFormData = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);

    var Formvlaues = Object.fromEntries(data.entries());
    Formvlaues.custId = _id;

    const formData = Formvlaues;

    console.log(Formvlaues);

    axios
      .post(`/v1/DID/add-union-saving`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            // window.location = "/agent-list";
            e.target.reset();
            custumerLoanDetail();
            SavinguserDeatailshistory();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const WithdrawalFormData = (e) => {
    console.log("--->", { WithtypeOfsavings });
    // Check the selected type and make the corresponding Axios API call
    if (WithtypeOfsavings === "mandatory") {
      // Make the API call for mandatory type using Axios
      axios
        .post(
          "/v1/super-Agent/withdrawal-request",
          {
            custId: _id,
            amount: withAmount,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your actual access token
            },
          }
        )
        .then((response) => {
          // Handle the response as needed
          let data = response.data;
          toast.success(data.msg);
          custumerLoanDetail();
        })
        .catch((error) => {
          toast.success(data.error);
          console.error("Error:", error);
        });
    } else {
      axios
        .post(
          "/v1/super-Agent/withdraw-saving",
          {
            custId: _id,
            amount: withAmount,
            type: "volantary",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Replace with your actual access token
            },
          }
        )
        .then((response) => {
          // Handle the response as needed
          let data = response.data;
          toast.success(data.msg);
          custumerLoanDetail();
          WithrawDeatailshistory();
        })
        .catch((error) => {
          toast.error(data.error);
          e.target.reset();
          // custumerLoanDetail();
          console.error("Error:", error);
        });
    }

    // Add additional conditions for other types if necessary
  };

  const custumerDetails = () => {
    axios
      .post(
        `/v1/super-Agent/cust-detail `,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;
        console.log("---->>>>--->>>", data);
        setUserinfo(data);

        console.log("data===== filter", data);
      });
  };

  const custumerLoanDetail = () => {
    axios
      .post(
        `/v1/DID/get-both-saving-amount`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        // let data = resp.data.data;
        let saveData = resp.data;
        // let numberOfSavings = resp.data.savingData.length;
        console.log("---->>>>--->>>Loan", saveData);
        // console.log("")
        setUserLoan(saveData);
        // NuberOfSavings(numberOfSavings);

        console.log("data===== filter", data);
      });
  };

  // saving Pagination done code
  const limit = 5;

  const SavinguserDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: 1, type: "deposite", savingType: "" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        console.log("=-=-=->", { totalPage });
        setpageCount(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  const fetchCommentspage = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: page, type: "deposite" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);
        console.log("Usersaving", saveData);

        console.log("Usersaving", data);
      });
    return data;
  };
  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };

  const [filterdata, setfilterdata] = useState("all");

  const fitteruserdata = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: 1, savingType: filterdata, type: "deposite" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setsavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage || 0);
      })
      .catch(error);
  };

  useEffect(() => {
    console.log({ filterdata });
    fitteruserdata();
  }, [filterdata]);

  // saving Pagination done end

  // withdraw Pagination done code  start

  const WithrawDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: 1, type: "withdrawal" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setVoluntaryToMandatoryApiData(saveData);
        console.log("withraw", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpage(totalPage || 0);
        console.log("withraw", data);
      });
  };

  const fetchCommentswithdraw = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: 1, type: "withdrawal" },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setVoluntaryToMandatoryApiData(saveData);
        console.log("withraw", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpage(totalPage || 0);
        console.log("withraw", data);
      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentswithdraw(page);
  };

  const Withdrawuserdata = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        { custId: _id, page: 1, savingType: withdrawtabfilter, type: "withdrawal" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setVoluntaryToMandatoryApiData(saveData);
        console.log("withraw", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setpage(totalPage || 0);
        console.log("withraw", data);
      })
      .catch(error);
  };

  useEffect(() => {
    Withdrawuserdata();
  }, [withdrawtabfilter]);

  // withdraw Pagination done code  end

  // Saving intrest api call

  const interestdatasApiCall = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest-transactions`,
        {
          custId: _id,
          page: 1,
          type: intrestfilterawdata == "all" ? "madatory" : intrestfilterawdata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setInterstSaving(saveData);
        console.log("withraw", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setSIpage(totalPage);
        console.log("withraw", data);
      });
  };

  const fetchCommentsavingintrest = async (page) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest-transactions`,
        {
          custId: _id,
          page: 1,
          type: intrestfilterawdata == "all" ? "madatory" : intrestfilterawdata,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setInterstSaving(saveData);
        console.log("saving", saveData);
        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / limit);
        setSIpage(totalPage);
        console.log("withraw", data);
      });
  };

  const SavingPageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentsavingintrest(page);
  };

  useEffect(() => {
    interestdatasApiCall();
  }, [intrestfilterawdata]);

  // const WithdrawalFormData = async (e) => {
  //   e.preventDefault();
  //   var data = new FormData(e.target);

  //   var Formvlaues = Object.fromEntries(data.entries());
  //   Formvlaues.custId = _id;

  //   const formData = Formvlaues;

  //   console.log({ Formvlaues });

  //   axios
  //     .post(`/v1/super-Agent/withdraw-saving`, formData, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })

  //     .then((resp) => {
  //       if (resp.status) {
  //         let data = resp.data;
  //         if (data.status) {
  //           toast.success(data.msg);
  //           // window.location = "/agent-list";
  //           e.target.reset();
  //           custumerLoanDetail();
  //           WithrawDeatailshistory();
  //         } else {
  //           toast.error(data.msg);
  //         }
  //       } else {
  //         toast.error(data.msg);
  //       }
  //     });
  // };

  const VoluntaryToMandatory = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);

    var Formvlaues = Object.fromEntries(data.entries());
    Formvlaues.custId = _id;

    const formData = Formvlaues;
    const amount = Formvlaues.amount;

    console.log({ amount });

    if (amount > userLoanDetail?.volantory) {
      Swal.fire({
        icon: "error",
        title: "Insufficient Funds",
        text: "You cannot withdraw this amount; your wallet does not have sufficient voluntary funds.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      axios
        .post(`/v1/super-Agent/Transfer-Fund`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })

        .then((resp) => {
          if (resp.status) {
            let data = resp.data;
            if (data.status) {
              toast.success(data.msg);
              // window.location = "/agent-list";
              e.target.reset();
              custumerLoanDetail();
              WithrawDeatailshistory();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
    }
  };

  const interestRatecount = async (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-interest`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        if (resp.status) {
          let data = resp?.data?.data;
          setNuberOfSavings(data);
        } else {
          toast.error(data.msg);
        }
      });
  };

  // api call
  useEffect(() => {
    hadlersuper();
    SavinguserDeatailshistory();
    custumerDetails();
    custumerLoanDetail();
    WithrawDeatailshistory();
    interestRatecount();
    interestdatasApiCall();
  }, []);

  const DeleteVeriflyAfterSubmit = () => {
    setDelethandler(true);
  };

  const VerfiyDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setDelethandler(false);
      let data = response.data;

      if (data.status) {
        if (WithtypeOfsavings == "voluntary") {
          Swal.fire({
            title: "Are you sure?",
            text: "Are You Sure To Withdrawal Amount.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              WithdrawalFormData();
            }
          });
        } else {
          Swal.fire({
            title: "Are you sure?",
            text: "Are you certain you want to withdraw the entire balance? Please note that upon withdrawal, your mandatory account will be closed permanently.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm it!",
          }).then((result) => {
            if (result.isConfirmed) {
              WithdrawalFormData();
            }
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  const Check_userAmount_to_wallet = (e) => {
    let usertypevalue = withAmount;

    console.log({ usertypevalue });

    if (WithtypeOfsavings == "mandatory") {
      if (usertypevalue > userLoanDetail.mandatory) {
        Swal.fire({
          icon: "error",
          title: "Insufficient Funds",
          text: "You cannot withdraw this amount; your wallet does not have sufficient mandatory funds.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        DeleteVeriflyAfterSubmit();
      }
    } else if (WithtypeOfsavings == "voluntary") {
      if (usertypevalue > userLoanDetail?.volantory) {
        Swal.fire({
          icon: "error",
          title: "Insufficient Funds",
          text: "You cannot withdraw this amount; your wallet does not have sufficient voluntary funds.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        DeleteVeriflyAfterSubmit();
      }
    } else {
      // Handle other types of savings if needed
    }
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={userPro.IDphoto}
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro.fullname}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.professoin}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.nationality || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex mt-3">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {userLoanDetail?.manStatus ==
                                            "Open" ? (
                                              userLoanDetail.mandatory
                                            ) : (
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                {userLoanDetail?.manStatus}{" "}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings
                                        </div>
                                      </div>

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              userLoanDetail?.volantory || 0
                                            }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Voluntary Savings
                                        </div>
                                      </div>

                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              userLoanDetail?.totalWithdrawal ||
                                              0
                                            }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total withdraw
                                        </div>
                                      </div> */}
                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              userLoanDetail?.totalInterest Rate|| 0
                                            }`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Intrest Amount
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="d-flex mt-3">
                                      {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${NumberOfsavings || 0}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total NUmber Of Savings
                                        </div>
                                      </div> */}
                                      {/* <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${data?.Loans || " N/A"}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Loans
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${data?.Savings || " N/A"}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Savings
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                ADD SAVINGS
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                WITHDRAWAL
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#VoluntaryToMandatory"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Voluntary To Mandatory
                              </a>
                            </li>
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#Savinginterest"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                              >
                                Saving Interest
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    {/* Add saving  */}

                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) => AddFormData(e)}
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Intrest Of Saving
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="interest"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Number of EMIs"
                                            />
                                          </div>
                                        </div>
                                      </div> */}

                                        <div className="col-lg-12 mb-8">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Type of Saving
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <select
                                                name="accType"
                                                className="form-select border-0 flex-grow-1"
                                                // data-control="select2"
                                                data-placeholder="Please select"
                                                onChange={(e) =>
                                                  setTypeOfsavings(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option>
                                                  Select Saving Type
                                                </option>
                                                <option value="mandatory">
                                                  Mandatory
                                                </option>
                                                <option value="volantary">
                                                  Voluntary
                                                </option>

                                                {/* Add other loan types as needed */}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Interest Rate
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            {/* <input
                                              type="number"
                                              name="interest"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Interest Rate"
                                              step="any"
                                              value={
                                                TypeOfsavings == "mandatory"
                                                  ? NumberOfsavings?.mandatory
                                                  : NumberOfsavings?.voluntary
                                              }
                                              readOnly
                                            /> */}
                                            <select
                                              name="interest"
                                              className="form-select border-0 flex-grow-1"
                                              // data-control="select2"
                                              data-placeholder="Please select"
                                              required
                                            >
                                              <option>
                                                Choose the Interest Rate
                                              </option>
                                              <option value={1}>1</option>
                                              <option value={2}>2</option>
                                              <option value={3}>3</option>
                                              <option value={4}>4</option>
                                              <option value={5}>5</option>
                                              <option value={6}>6</option>
                                              <option value={7}>7</option>
                                              <option value={8}>8</option>
                                              <option value={9}>9</option>
                                              <option value={10}>10</option>
                                              <option value={11}>11</option>
                                              <option value={12}>12</option>
                                              <option value={13}>13</option>
                                              <option value={14}>14</option>
                                              <option value={15}>15</option>
                                              <option value={16}>16</option>
                                              <option value={17}>17</option>
                                              <option value={18}>18</option>
                                              <option value={19}>19</option>
                                              <option value={20}>20</option>

                                              {/* Add other loan types as needed */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="cardlabel fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="savingType"
                                          value="all"
                                          onChange={(e) =>
                                            setfilterdata(e.target.value)
                                          }
                                          checked={filterdata === "all"}
                                          className="form-check-input"
                                        />
                                        All
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="savingType"
                                          value="mandatory"
                                          onChange={(e) =>
                                            setfilterdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="savingType"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setfilterdata(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      <th className="min-w-100px">
                                        Interest
                                        <br /> Rate{" "}
                                      </th>
                                      <th className="min-w-100px">Type</th>
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {savingsHistory.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.totalAmount.toFixed(2)}</td>
                                        <td>
                                          {item?.savingType == "mandatory"
                                            ? "Mandatory"
                                            : "Voluntary"}
                                        </td>
                                        <td>{item?.interest.toFixed(2)}</td>
                                        <td>
                                          {item?.type == "deposite"
                                            ? "Deposit"
                                            : item?.type}
                                        </td>
                                        <td>
                                          <span className="text-success">
                                            Success
                                          </span>
                                        </td>
                                        {/* <td>
                                          <div className="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                    

                      <div className="col-lg-6">
                        <div
                          className="card mb-5 mb-xl-10"
                          id="kt_profile_details_view"
                        >
                          <div className="card-header cursor-pointer">
                            <div className="card-body p-9">
                              <div
                                id="kt_account_settings_profile_details"
                                className="collapse show"
                              >
                                <form
                                  onSubmit={(e) => AddFormData(e)}
                                  id="kt_account_profile_details_form"
                                  className="form"
                                >
                                  <div className="card-body border-top p-9">
                                    <div className="row mb-8">
                                      <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Amount
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="amount"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Amount"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mb-8">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Intrest Of Saving
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <input
                                              type="number"
                                              name="interest"
                                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                              placeholder="Number of EMIs"
                                            />
                                          </div>
                                        </div>
                                      </div>

                            
                                    </div>

                      

                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                      <button
                                        type="submit"
                                        className="btn btn-light btn-active-light-primary me-2"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* withdraw */}

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    // onSubmit={(e) => WithdrawalFormData(e)}
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                                value={withAmount}
                                                onChange={(e) =>
                                                  setWithAmount(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mb-2">
                                        <div className="row">
                                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            Type of Saving
                                          </label>
                                          <div className="col-lg-8 fv-row">
                                            <select
                                              name="type"
                                              value={formData.type}
                                              onChange={(e) =>
                                                setWithTypeOfsavings(
                                                  e.target.value
                                                )
                                              }
                                              className="form-select border-0 flex-grow-1"
                                              data-placeholder="Please select"
                                            >
                                              <option value="mandatory">
                                                Mandatory
                                              </option>
                                              <option value="voluntary">
                                                Voluntary
                                              </option>
                                              {/* Add other saving types as needed */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            Check_userAmount_to_wallet()
                                          }
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="withdraw"
                                          value="all"
                                          checked={withdrawtabfilter === "all"}
                                          onChange={(e) =>
                                            setwithdrawtabfilter(e.target.value)
                                          }
                                       
                                          className="form-check-input"
                                        />
                                        All
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="withdraw"
                                          value="mandatory"
                                          onChange={(e) =>
                                            setwithdrawtabfilter(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="withdraw"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setwithdrawtabfilter(e.target.value)
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      {/* <th className="min-w-100px">
                                        Intrest
                                        <br /> Rate{" "}
                                      </th> */}
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {VoluntaryToMandatoryApiData.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.totalAmount.toFixed(2)}</td>
                                        <td>{item?.type}</td>
                                        {/* <td>
                                          {item.remainingAmount.toFixed(2)}
                                        </td> */}
                                        <td>
                                          <span className="text-success">
                                            Success
                                          </span>
                                        </td>
                                        {/* <td>
                                          <div className="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={page}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* savingtarget */}

                    <div
                      className="tab-pane fade"
                      id="Savinginterest"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                              <h3 className="card-title align-items-start flex-column">
                                <div className="row">
                                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                                    <div className="d-flex">
                                      {/* <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="all"
                                          onChange={(e) =>
                                            setintrestfilterawdata(
                                              e.target.value
                                            )
                                          }
                                          checked={
                                            intrestfilterawdata === "all"
                                          }
                                          className="form-check-input"
                                        />
                                        All
                                      </label> */}
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="mandatory"
                                          checked={
                                            intrestfilterawdata == "mandatory"
                                          }
                                          onChange={(e) =>
                                            setintrestfilterawdata(
                                              e.target.value
                                            )
                                          }
                                          className="form-check-input"
                                        />
                                        Mandatory
                                      </label>
                                      <label className="form-check form-check-inline fs-6">
                                        <input
                                          type="radio"
                                          name="Type"
                                          value="voluntary"
                                          onChange={(e) =>
                                            setintrestfilterawdata(
                                              e.target.value
                                            )
                                          }
                                          className="form-check-input"
                                        />
                                        Voluntary
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Month Date
                                      </th>
                                      <th className="min-w-150px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Interest Amount
                                      </th>
                                      {/* <th className="min-w-100px">
                                        Intrest
                                        <br /> Rate{" "}
                                      </th> */}
                                      <th className="min-w-100px">
                                        Total Balance
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {interestData.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.date).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{0}</td>
                                        <td>{item?.totalInterest}</td>
                                        <td>{item?.balance}</td>
                                        {/* <td>
                                          {item.remainingAmount.toFixed(2)}
                                        </td> */}

                                        {/* <td>
                                          <div className="d-flex justify-content-end flex-shrink-0">
                                            <a
                                              href="digital_user_detail.html"
                                              className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fad fa-eye fs-4"></i>
                                              </span>
                                            </a>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={SIpage}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={SavingPageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Voluntary To Mandatory */}

                    <div
                      className="tab-pane fade"
                      id="VoluntaryToMandatory"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-body p-9">
                                <div
                                  id="kt_account_settings_profile_details"
                                  className="collapse show"
                                >
                                  <form
                                    onSubmit={(e) => VoluntaryToMandatory(e)}
                                    id="kt_account_profile_details_form"
                                    className="form"
                                  >
                                    <div className="card-body border-top p-9">
                                      <div className="row mb-8">
                                        <div className="col-lg-12 mb-1">
                                          <div className="row">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                              Amount
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                              <input
                                                type="number"
                                                name="amount"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Amount"
                                                step="any"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <button
                                          type="submit"
                                          className="btn btn-light btn-active-light-primary me-2"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">
                                  Recent Transactions
                                </span>
                                <span className="text-muted mt-1 fw-semibold fs-7"></span>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bold text-muted th-title">
                                      <th className="min-w-125px">
                                        Transaction <br /> Date{" "}
                                      </th>
                                      <th className="min-w-150px">
                                        Total
                                        <br />
                                        Amount
                                      </th>
                                      <th className="min-w-150px">
                                        Saving <br /> Type{" "}
                                      </th>
                                      <th className="min-w-100px">
                                        Interest
                                        <br /> Rate{" "}
                                      </th>
                                      <th className="min-w-100px text-center">
                                        Status <br />
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {VoluntaryToMandatoryApiData.map((item) => (
                                      <tr key={item?.id}>
                                        <td>
                                          {moment(item?.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </td>
                                        <td>{item?.totalAmount.toFixed(2)}</td>
                                        <td>{item?.type}</td>
                                        <td>
                                          {item?.remainingAmount.toFixed(2)}
                                        </td>
                                        <td>
                                          <span className="text-success">
                                            Success
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={page}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end gap-sm-3"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="-400 fw-semibold me-1">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* details modal  */}

          <Modal
            className="modal-center"
            show={Delethandler}
            onHide={() => setDelethandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyDelete}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Verify
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Savingcustomer;
