import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useNavigate } from "react-router-dom";
var jwt = require("jsonwebtoken");

function MandatorySavings({ hadlersuper }) {
    const navigate = useNavigate();
    let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let orgID = decode1.OrganisationID;

    const [pageCount, setpageCount] = useState("");
    const [notereason, setnotereason] = useState("");
    const [total, settotal] = useState([]);
    const [Delethandler, setDelethandler] = useState(false);
    const [customerloanid, setcustomerloanid] = useState('');


    let ID = localStorage.getItem("ID");
    const [data, setData] = useState([]);
    const [paymenthandler, setpaymenthandler] = useState(false);
    const [userdetails, setuserdetails] = useState([]);

    const limit = 10;

    const ActiveCustomerpassList = async () => {
        await axios
            .post(
                `/v1/super-Agent/get-total-savings`,
                { page: "1", savingType: "mandatory" },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data;
                console.log(
                    "data===== ActiveCustomer pending list totalPagetotalPagetotalPagetotalPagetotalPagetotalPage",
                    data
                );
                let filter = data.data;
                setuserdetails(filter);

                const total = resp.data.totalRow;
                settotal(total);

                const totalPage = Math.ceil(total / limit);
                setpageCount(totalPage);

                console.log("data===== filter", filter);
            });
    };

    const VerfiyDelete = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        try {
            const response = await axios.post(
                `/v1/super-Agent/match-agent-pass`,
                formData,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setDelethandler(false);
            let data = response.data;
            if (data.status) {
                LoanRejected()
            }
        } catch (error) {

        }
    };

    const fetchComments = async (page) => {
        const senData = { page: page };
        // const cosole = Fromvalue;
        // console.log(Fromvalue);
        await axios
            .post(
                `/v1/super-Agent/get-total-savings`,

                { senData },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data;
                let filter = data.data;
                console.log({ filter });
                setuserdetails(filter);
            });
    };

    const handlePageClick = async (data) => {
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setuserdetails(commentsFormServer);
    };

    useEffect(() => {
        ActiveCustomerpassList();
    }, []);

    ///////////////pagenestion///////////////

    const formsave = (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log("Formvlaues === ", Formvlaues);
        axios
            .post(`/v1/super-Agent/get-total-savings`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((resp) => {
                const data = resp.data.filter;
                setData(data);
                const total = resp.data.totlaRow;
                console.log(total);
                const totalPage = Math.ceil(total / limit);
                setpageCount(totalPage);
            });
    };

    /////////////unblocked user api call ////////////

    /////////////blocked user api call ////////////

    /////////////delete user api call ////////////
    const LoanRejected = () => {
        Swal.fire({
            title: "Enter Loan Reject Ression",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit ",
            showLoaderOnConfirm: true,
            preConfirm: async (rejectReason) => {
                return await axios
                    .post(
                        `/v1/super-Agent/cancel-loan`,
                        { reason: rejectReason, loanId: customerloanid },
                        { headers: { Authorization: `bearer ${token}` } }
                    )
                    .then((response) => {
                        let data = response.data;
                        {
                            data.status
                                ? Swal.fire(
                                    "Successfully",
                                    // navigate(`/Agent-customer-view/${_id}`)
                                    ActiveCustomerpassList()
                                )
                                : Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Invalid OTP!",
                                });
                        }
                    })
                    .catch();
            },
        });
    };

    useEffect(() => {
        hadlersuper();
    }, []);
    return (
        <>
            <ToastContainer />
            <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
            >
                <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                >
                    <div className="container-xxl">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div
                                    className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <div className="card-body container-xxl pt-10 pb-8">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-semibold me-3 text-white">Filter</h1>
                                            <span className="fw-semibold text-white opacity-50">
                                                Active Loans
                                            </span>
                                        </div>
                                        <form onSubmit={(e) => formsave(e)}>
                                            <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                    <div className="col-lg-4 col-sm-4   d-flex input-container align-items-center mb-3  mb-lg-0">
                                                        <label className="small_label">Start Date</label>
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                            <i className="fad fa-calendar fs-2"></i>
                                                        </span>
                                                        <input
                                                            type="date"
                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                            name="fromDate"
                                                            placeholder="from Date.."
                                                        />
                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                    </div>

                                                    <div className="col-lg-4 col-sm-4   d-flex align-items-center mb-3 input-container mb-lg-0">
                                                        <label className="small_label">End Date</label>
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                            <i className="fad fa-calendar fs-2"></i>
                                                        </span>
                                                        <input
                                                            type="date"
                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                            name="toDate"
                                                            placeholder="to Date.."
                                                        />
                                                    </div>
                                                </div>

                                                <div className="min-w-175px d-flex gap-1">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-dark  mx-sm-3"
                                                        id="kt_advanced_search_button_1"
                                                    >
                                                        Search
                                                    </button>
                                                    <button
                                                        type="reset"
                                                        onClick={() => ActiveCustomerpassList()}
                                                        className="btn btn-secondary"
                                                        id="kt_advanced_search_button_1"
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                    <div class="card-header border-0 pt-6 d-flex align-items-center">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3 mb-1">
                                                Mandatory Savings
                                            </span>
                                            <span className="text-muted mt-1 fw-semibold fs-7">
                                                {" "}
                                                Over {total} Savings
                                            </span>
                                        </h3>
                                        <div class="card-title"></div>
                                    </div>

                                    <div className="card-body py-3">
                                        <div className="table-responsive">
                                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                <thead>
                                                    <tr className="fw-bold text-muted th-title">
                                                        <th className="min-w-125px">ID Photo</th>
                                                        <th className="min-w-125px">Saving Amount</th>

                                                        {/* <th className="min-w-150px">Interest</th> */}
                                                        {/* <th className="min-w-150px"> Interest Rate</th> */}
                                                        {/* <th className="min-w-100px">Time Period</th> */}
                                                        <th className="min-w-100px">Total Amount</th>
                                                        {/* <th className="min-w-100px">
                                facial Identification
                              </th> */}
                                                        <th className="min-w-100px ">Status</th>
                                                        <th className="min-w-100px text-center">Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {userdetails?.map((item) => (
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <div class="d-flex align-items-center">
                                                                    <div class="symbol symbol-45px me-5">
                                                                        <img src={item?.custId?.IDphoto} alt="" />
                                                                    </div>
                                                                    <div class="d-flex justify-content-start flex-column">
                                                                        <a
                                                                            //   href={`/org-customer-view/${item._id}`}
                                                                            class="usertitle Acolor mb-2"
                                                                        >
                                                                            {" "}
                                                                            {item?.custId?.fullname}
                                                                        </a>
                                                                        <span class="font-icon mb-1">
                                                                            <span class="">
                                                                                <i class="fal fa-phone-volume"></i>
                                                                            </span>
                                                                            +{item?.custId?.phone}
                                                                        </span>

                                                                        <span class="font-icon mb-1">
                                                                            <span class="">
                                                                                <i class="fal fa-envelope"></i>
                                                                            </span>
                                                                            {item?.custId?.email}{" "}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item?.totalAmount}</td>
                                                            {/* <td>{item?.totalInterestAmount}</td> */}
                                                            {/* <td>{item?.interestRate} %</td> */}
                                                            {/* <td>{item?.loanDuration}</td> */}
                                                            <td>{item?.totalAmount}</td>

                                                            {item.status == "PENDING" ? (
                                                                <>
                                                                    <td>
                                                                        <span className="badge badge-light-pending fs-5">
                                                                            {item.status}
                                                                        </span>
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td>
                                                                        <span className="badge badge-light-info fs-5">
                                                                            Active
                                                                        </span>
                                                                    </td>
                                                                </>
                                                            )}

                                                            <td>
                                                                <div className="d-flex justify-content-end flex-shrink-0">
                                                                    <button
                                                                        // onClick={() =>
                                                                        //     navigate(
                                                                        //         `/Super-Agent/Emi-loan-detail/${item._id}`
                                                                        //     )
                                                                        // }
                                                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        data-bs-trigger="hover"
                                                                        title="View Detail"
                                                                    >
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <i className="fad fa-eye fs-4"></i>
                                                                        </span>
                                                                    </button>

                                                                    <button
                                                                        // onClick={(e) => {


                                                                        //     setDelethandler(true);
                                                                        //     setcustomerloanid(item._id);

                                                                        // }}
                                                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        data-bs-trigger="hover"
                                                                        title="Cancel Loan"
                                                                    >
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <img
                                                                                src="/assets_new/images/cross.png"
                                                                                width="20px"
                                                                            />
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        data-bs-trigger="hover"
                                                                        title="Disable OTP"
                                                                    >
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <i className="fad fa-eye-slash"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-lg-12 mt-2 text-end">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={1}
                                                onPageChange={handlePageClick}
                                                containerClassName={
                                                    "pagination justify-content-end gap-2"
                                                }
                                                pageClassName={"page-item mx-2"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link pagestyle"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="modal-center"
                show={Delethandler}
                onHide={() => setDelethandler(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Verify Password</Modal.Title>
                </Modal.Header>
                <form onSubmit={VerfiyDelete}>
                    <Modal.Body>
                        <div className="col-lg-12 ">
                            <div className="row">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    {" "}
                                    Password
                                </label>
                                <div className="col-lg-8 fv-row">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        placeholder="Enter password"
                                    />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className="center">
                            {" "}
                            Verify
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default MandatorySavings
