

import React, { useState, useEffect} from 'react'
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from 'axios';

import { useParams } from "react-router-dom";

import { error } from 'jquery';

function Admin_user_profile_View({hadleradmin}) {

    const {_id} = useParams();
    const [data, setData] = useState("");
    const userlist = async () => {
      const {sub_adminID}= {sub_adminID:_id}
      await axios.get(`/v1/admin/Sub_admin_profil/${sub_adminID}`,).then((resp) => {
        const data1 = resp.data.find;
        setData(data1);
        console.log("userprofile api ",data1) 
      
      }).catch(error) ;
    };
    useEffect(() => {
      userlist();
      hadleradmin();
    }, []);

 
  return (
    <div>
      
    <div
    className="wrapper d-flex flex-column flex-row-fluid"
    id="kt_wrapper"
  >
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-xxl" id="kt_content_container">
        <div className="row g-5 g-xl-8">
          <div className="col-lg-12">
            <div className="card mb-5 mb-xl-10">
              <div
                className="card-header border-0 cursor-pointer justify-content-evenly "
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
              >
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">My Profile</h3>
                </div>
              
              </div>



              <div className="container">
                <div className="row">
                  <div className="col-md-12 m-auto">
                <img src="\assets\images\rohit.jpg" alt="userimage" className='d-block m-auto rounded-circle' width="20%" srcset="" />
                </div>
                </div>
              </div>
              <div
                id="kt_account_settings_profile_details"
                className="collapse show"
              >
                <form
                //   onSubmit={(e) => UpdateFormData(e)}
                  id="kt_account_profile_details_form"
                  className="form"
                >
                  <div className="card-body border-top p-9">
                    <div className="row mb-6 justify-content-center">
                      <div className="col-lg-9">
                        <div className="row mb-6">
                          <div className="col-lg-6 fv-row mb-6">
                            <label className="col-form-label required fw-semibold fs-6">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="Firstname"
                              defaultValue={data.Firstname}
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Full Name"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Last Name{" "}
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              defaultValue={data.lastName}
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Email{" "}
                            </label>
                            <input
                              type="email"
                              name="email"
                              defaultValue={data.email}
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Email Address"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Password{" "}
                            </label>
                            <input
                              type="password"
                              name="password"
                              defaultValue={data.password}
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Password"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Phone
                            </label>
                            <input
                              type="number"
                              name="phone"
                              defaultValue={data.phone}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="Phone"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                            Address
                            </label>
                            <input
                              type="text"
                              name="address"
                              defaultValue={data.address}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="Addres"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              defaultValue={data.country}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="Country"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              State
                            </label>
                            <input
                              type="text"
                              name="state"
                              defaultValue={data.state}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="State"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              defaultValue={data.city}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="City"
                            />
                          </div>
                          <div className="col-lg-6 fv-row mb-6">
                            <label className=" col-form-label fw-semibold fs-6">
                              Post Code
                            </label>
                            <input
                              type="number"
                              name="postCode"
                              defaultValue={data.postCode}
                              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
                              placeholder="Post Code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      <button
                        type="submit"
                        className="btn btn-light btn-active-light-primary me-2"
                        style={{
                          backgroundColor: "#3c7f8c",
                          color: "#fff",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}


export default Admin_user_profile_View
