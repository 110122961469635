import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
var jwt = require("jsonwebtoken");

function FaceHistoryList({ hadleradmin }) {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [pageCount, setpageCount] = useState("");
    const [menu, setMenu] = useState([]);
    const [total, settotal] = useState([]);
    const [Query, setQuery] = useState("");

    const limit = 10;

    let token = localStorage.getItem("ChromePayAdmitToken");
    var decode1 = jwt.decode(token);
    let adminID = decode1.admminID;

    const AddFormData = async (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log(Formvlaues);
        axios.post(`/v1/admin/get-face-history`, formData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);

            const total = resp.data.totalRows;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };

    const userlist = async () => {
        await axios.post(`/v1/admin/get-face-history`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            console.log("CustomerList >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> userlist data show", data)

            const total = resp.data.totalRows;
            console.log(total);
            settotal(total)
            console.warn("CustomerList", data);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);

        });
    };
    useEffect(() => {
        userlist();
    }, []);

    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
        const senData = { page: page };
        // const cosole = Fromvalue;
        // console.log(Fromvalue);
        axios.post(`/v1/admin/get-face-history`, senData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;

            setData(data);
        });
        return data;
    };

    const handlePageClick = async (data) => {
        // console.log(data.selected);
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setData(commentsFormServer);
    };

    /////////////// unblocked  ////////////////////////
    const handleunblocked = (_id) => {
        console.warn(_id);
        axios.put(`/v1/admin/Unsuspendcustomer/${_id}/${adminID}`).then((res) => {
            if (res.status) {
                let data = res.data;
                toast.success(data.msg);
                return userlist();
            }
        });
    };

    /////////////////delete api call /////////////////
    const handlesuspend = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to Suspend selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Suspend it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`/v1/admin/suspendcustomer/${_id}/${adminID}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire("Suspend!", "Customer Suspend successfully", "success");
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

    /////////////////delete api call /////////////////
    const handledelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/v1/admin/deleteCustomer/${_id}/${adminID}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire("Deleted!", "Customer deleted successfully", "success");
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };
    const handleView = (_id) => {
        console.log(_id);
        navigate(`/customer-view-admin/${_id}`);
        return false;
    };

    const handleVerfiy = (_id) => {
        const { custID } = { custID: _id };
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to verify selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, verify it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`/v1/admin/customerVerify/${custID}/${adminID}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire(
                                "verified!",
                                "Customer verified successfully",
                                "success"
                            );
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

    const subAdminlist = async () => {
        await axios.post(`v1/admin/subAdminRole/${adminID}`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            let data = resp.data.find;
            // console.log('@@@@@',data.customer.addCustomer)
            setMenu(data.customer);
        });
    };

    useEffect(() => {
        subAdminlist();
        hadleradmin();
    }, []);

    return (
        <>
            <ToastContainer />

            <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
            >
                <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                >
                    <div className="container-xxl" id="kt_content_container">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div
                                    className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                    style={{ marginTop: "2rem" }}
                                >
                                    <div className="card-body container-xxl pt-10 pb-8">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-semibold me-3 text-white">Filter</h1>
                                            <span className="fw-semibold text-white opacity-50">
                                                Face History
                                            </span>
                                        </div>

                                        <div className="d-inline-flex d-flex flex-column">
                                            <div className=" align-lg-items-center">
                                                <form onSubmit={(e) => AddFormData(e)}>
                                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                            {/* <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="ID"
                                  placeholder="Enter D-ID ref"
                                />
                              </div> */}

                                                            {/* <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="phone"
                                  placeholder="Mobile Number"
                                />
                              </div> */}

                                                            {/* <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  name="status"
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option defaultValue="" selected disabled>
                                    Status
                                  </option>
                               
                                  <option defaultValue="verified">
                                    Verified
                                  </option>
                                  <option defaultValue="pending">
                                    pending
                                  </option>
                                </select>
                              </div> */}

                                                            <div className="col-lg-12 d-flex align-items-center mb-5 mb-lg-0">
                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <rect
                                                                            x="2"
                                                                            y="2"
                                                                            width="9"
                                                                            height="9"
                                                                            rx="2"
                                                                            fill="currentColor"
                                                                        />
                                                                        <rect
                                                                            opacity="0.3"
                                                                            x="13"
                                                                            y="2"
                                                                            width="9"
                                                                            height="9"
                                                                            rx="2"
                                                                            fill="currentColor"
                                                                        />
                                                                        <rect
                                                                            opacity="0.3"
                                                                            x="13"
                                                                            y="13"
                                                                            width="9"
                                                                            height="9"
                                                                            rx="2"
                                                                            fill="currentColor"
                                                                        />
                                                                        <rect
                                                                            opacity="0.3"
                                                                            x="2"
                                                                            y="13"
                                                                            width="9"
                                                                            height="9"
                                                                            rx="2"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <select
                                                                    className="form-select border-0 flex-grow-1"
                                                                    data-control="select2"
                                                                    data-placeholder="Type"
                                                                    data-hide-search="true"
                                                                    name="type"
                                                                >
                                                                    <option value="" selected disabled>
                                                                        Type
                                                                    </option>
                                                                    <option value="passport">Passport</option>
                                                                    <option value="kabele">Kabele</option>
                                                                    {/* <option value="Uganda">Uganda</option> */}
                                                                </select>
                                                            </div>

                                                            {/* <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                <label className="small_label">
                                                                    Start Date
                                                                </label>
                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                </span>
                                                                <input
                                                                    type="date"
                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                    name="fromDate"
                                                                    placeholder="from Date.."
                                                                />
                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                            </div>

                                                            <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                <label className="small_label">End Date</label>
                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                </span>
                                                                <input
                                                                    type="date"
                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                    name="toDate"
                                                                    placeholder="to Date.."
                                                                />
                                                            </div> */}
                                                        </div>

                                                        <div className="min-w-150px d-flex">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-dark"
                                                                id="kt_advanced_search_button_1"
                                                            >
                                                                Search
                                                            </button>
                                                            <button
                                                                type="reset"
                                                                onClick={userlist}
                                                                className="btn btn-secondary"
                                                                id="kt_advanced_search_button_1"
                                                            >
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                    <div class="card-header border-0 pt-6 d-flex align-items-center">

                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3 mb-1">
                                                Face History List
                                            </span>
                                            <span className="text-muted mt-1 fw-semibold fs-7"> Over  {total}  Customer</span>
                                        </h3>
                                        {/* <div class="card-title">

                                            <div class="d-flex align-items-center position-relative my-1">

                                                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>

                                                <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Customers" onChange={(e) => setQuery(e.target.value)} />
                                            </div>

                                        </div> */}


                                    </div>







                                    <div className="card-body py-3">
                                        <div className="table-responsive">
                                            <table
                                                className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                                                id="table-to-xls"
                                            >
                                                <thead>
                                                    <tr className="fw-bold text-muted th-title">
                                                        <th className="min-w-125px">User Image</th>
                                                        <th className="min-w-125px">Kabele Image</th>

                                                        <th className="min-w-150px">Type</th>
                                                        <th className="min-w-150px">Package Name</th>
                                                        <th className="min-w-150px">	Date of Creation</th>


                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {data.map((item) => (
                                                        <tr>
                                                            <td> <div class="d-flex align-items-center">
                                                                <div class="symbol symbol-45px me-5">
                                                                    <img src={item.userImage} alt="" />
                                                                </div>

                                                            </div></td>
                                                            <td> <div class="d-flex align-items-center">
                                                                <div class="symbol symbol-45px me-5">
                                                                    {item.type == "facematch" ? 
                                                                    <>
                                                                    <img src="https://shadi-app.s3.ap-southeast-2.amazonaws.com/abc/noun-no-image-3378172.png" alt="" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <img src={item.kabeleImage} alt="" />
                                                                    </>
                                                                    }
                                                                    
                                                                </div>

                                                            </div></td>




                                                            <td>
                                                                {item.type}
                                                            </td>


                                                            <td>
                                                                {item.packageName}
                                                            </td>

                                                            <td>
                                                                {Moment(item.createdAt).format("DD/MM/YYYY")}
                                                            </td>






                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-lg-12 mt-2 text-end">
                                            <ReactPaginate
                                                previousLabel={"previous"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end"}
                                                pageClassName={"page-item mx-2"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link pagestyle"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}





export default FaceHistoryList