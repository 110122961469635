import React from "react";
import { createWorker } from "tesseract.js";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState,useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import { error } from "jquery";
var jwt = require("jsonwebtoken");
// import { createWorker } from "tesseract.js";




function ImageReader() {

    const [ocrText, setOcrText] = useState("");
    const [progress, setProgress] = useState(0);
    const [imageData, setImageData] = useState(null);
    const [RegID, setRegID] = useState([]);
    const [IDNO, setIDNO] = useState("");
    const [fullname, setfullname] = useState("");
    const [DOB, setDOB] = useState("");
    const [BloodGroup, setBloodGroup] = useState("");
    const [Sex, setSex] = useState("");
    const [IssueDate, setIssueDate] = useState("");
    const [ExpiryDate, setExpiryDate] = useState("");

    const navigate = useNavigate();
   let token = localStorage.getItem("ChromePayAdmitToken");
    const AddFormData = (e) => {
        e.preventDefault();
       
         const data = new FormData(e.target);
         const Formvlaues = Object.fromEntries(data.entries());
     console.log("first form data ",Formvlaues)

    


        Object.keys(Formvlaues).forEach((key,) => {
          
            Formvlaues[key] = Formvlaues[key]==="on"? 1:Formvlaues[key]
            Formvlaues[key] = Formvlaues[key]==="off"? 0:Formvlaues[key]
            console.log("empolylistdata.....",Formvlaues)
          });

         axios.post(`/v1/org/CRM/Create-Employee`,Formvlaues , {headers:{"Authorization":`Bearer ${token}`}})
         .then(resp =>{
            if (resp.status) {
                let data = resp.data;
                if (data.status) {
                    toast.success(data.msg);
                    navigate("/EmpolyListORG");
                 
                    e.target.reset();
                } else {
                    toast.error(data.msg);

                }
            } else {
                toast.error(data.msg)
            }


         }).catch(error)
     
     
        }
      
        
        
        const [Other, setOther]= useState('')

console.log("=====>",Other)

function changeOther(event) {
    setOther(event.target.value);
  }
        
        useEffect(()=>{
            
        },[])




        function structureData() {
            let RegID = ocrText.slice(18, 30)
            let IDNO = ocrText.slice(56, 80)
            let fullname = ocrText.slice(133, 159)
            let DOB = ocrText.slice(187, 208)
            let BloodGroup = ocrText.slice(237, 240)
            let Sex = ocrText.slice(251, 253)
            let IssueDate = ocrText.slice(279, 301)
            let ExpiryDate = ocrText.slice(349, 384)
        
            console.log("RegID", RegID)
            console.log("IDNO", IDNO)
            console.log("fullname", fullname)
            console.log("DOB", DOB)
            console.log("BloodGroup", BloodGroup)
            console.log("Sex", Sex)
            console.log("IssueDate", IssueDate)
            console.log("ExpiryDate", ExpiryDate)
        
        
        
            setRegID(RegID);
            setIDNO(IDNO)
            setfullname(fullname)
            setDOB(DOB)
            setBloodGroup(BloodGroup)
            setSex(Sex)
            setIssueDate(IssueDate)
            setExpiryDate(ExpiryDate)
          }
        
          const worker = createWorker({
            logger: (m) => {
              // console.log(m);
              setProgress((m.progress * 100).toFixed(2));
            },
          });
          const convertImageToText = async () => {
            if (!imageData) return;
            await worker.load();
            await worker.loadLanguage("eng");
            await worker.initialize("eng");
            const {
              data: { text },
            } = await worker.recognize(imageData);
            setOcrText(text);
          };
        
          useEffect(() => {
            convertImageToText();
            structureData(ocrText)
          }, [imageData]);
        
          function handleImageChange(e) {
            const file = e.target.files[0];
            if (!file) return;
            const reader = new FileReader();
            reader.onloadend = () => {
              const imageDataUri = reader.result;
              console.log({ imageDataUri });
              setImageData(imageDataUri);
            };
            reader.readAsDataURL(file);
          }
  return (
    <>
    <div className="App">
      <div>
        <span className="source-file">
          <label htmlFor="file-source">Choose an Image</label>
          <input
            type="file"
            id="file-source"
            onChange={(e) => handleImageChange(e)}

            accept="image/*"
          />
        </span>
        <img src={imageData} alt="" srcSet="" className="image-preview" />
      </div>
      <div className="display-flex">
        <span className="progress">
          <label htmlFor="progress"> progress  - {progress}%</label>
          <meter
            id="progress" min="0" max="100"
            low={30} high={60} optimum={90} value={progress}
          >
            0%
          </meter>
        </span>
        <p>{ocrText}</p>

        <div className="row mb-6">
          <div className="col-lg-6 fv-row mb-6">
            <label className="col-form-label  fw-semibold fs-6">
              Reg.
            </label>
            <input
              type="text"
              name="Reg.Id"
              defaultValue={RegID}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Reg.Id"
            />
          </div>
          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Last Name{" "}
            </label>
            <input
              type="text"
              name="lastName"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Last Name"
            />
          </div>
          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Email{" "}
            </label>
            <input
              type="email"
              name="email"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              placeholder="Email Address"
            />
          </div>

          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
              placeholder="Phone"
            />
          </div>

          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
              placeholder="Phone"
            />
          </div>

          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
              placeholder="Phone"
            />
          </div>

          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
              placeholder="Phone"
            />
          </div>

          <div className="col-lg-6 fv-row mb-6">
            <label className=" col-form-label fw-semibold fs-6">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              defaultValue={123}
              className="form-control form-control-lg form-control-solid fs-6 mb-lg-0"
              placeholder="Phone"
            />
          </div>
        </div>

      </div>

    </div>
    <footer>
      <em>&copy; 2022 - <a href="http://blessedjasonmwanza.me" target={'_blank'}>Blessed Jason Mwanza</a></em>
    </footer>

    <div id="kt_account_settings_profile_details" className="collapse show">
      <form id="kt_account_profile_details_form" className="form" >
        <div className="card-body border-top p-9">
          {/* <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label fw-semibold fs-6">ID Photo</label>
                                          <div className="col-lg-8">
                                              <div className="image-input image-input-outline" data-kt-image-input="true" style={{backgroundImage: "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')"}}>
                                                  <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${userviewphoto})`}}></div>

                                                 
                                                     <input type="hidden" name="Latitude" value={lat} />
                                                     <input type="hidden" name="Longitude" value={long} />
                                                 
                                                  <label   className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Upload Image">
                                                      <i className="bi bi-pencil-fill fs-7" ></i>
                                                      <input type="hidden" name="image"  />
                                                  
                                                  </label>
                                                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                      <i className="bi bi-x fs-2"></i>
                                                  </span>
                                                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                      <i className="bi bi-x fs-2"></i>
                                                  </span>
                                              </div>
                                              <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                          </div>
                                      </div> */}





          <div className="row mb-6">

            <label className="col-lg-4 col-form-label  fw-semibold fs-6">Full Name</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 fv-row">
                  {/* <input type="hidden" name="organisation" defaultValue={organisation} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full Name"  /> */}
                  <input type="text" name="first_name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full Name" />
                </div>
              </div>
            </div>

          </div>
          <div className="row mb-6">

            <label className="col-lg-4 col-form-label  fw-semibold fs-6"> Last Name</label>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12 fv-row">

                  <input type="text" name="last_name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="last Name" />
                </div>
              </div>
            </div>

          </div>




          {/* <div className="row mb-6">
                                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">Date of Birth</label>
                                          <div className="col-lg-8 fv-row">
                                              <input type="date" name="dateOfBirth"  id="date_timepicker_end"  className="form-control form-control-lg form-control-solid position-relative" />
                                          </div>
                                      </div> */}

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              <span className="">Contact Phone</span>
              <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
            </label>
            <div className="col-lg-8 fv-row">

            </div>
          </div>
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Email Address</label>
            <div className="col-lg-8 fv-row">
              <input type="email" name="email" className="form-control form-control-lg form-control-solid" placeholder="Email Address" />
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Employee Type</label>
            <div className="col-lg-8 fv-row">
              <select className="form-select border-0 flex-grow-1" data-control="select2" data-placeholder="Please select" data-hide-search="true" name="employee_type">
                <option value="" selected disabled>Please select</option>
                <option value="Agent">Agent</option>
                <option value="Office Worker">Office Worker </option>
                <option value="Loan Collector">Loan Collector</option>
                <option value="Loan Dealer">Loan Dealer</option>
                <option value="Cashier">Cashier</option>
                <option value="DID Manager">DID Manager</option>
                <option value="other">Other</option>

              </select>
              {/* {Other=="other" ?<><input type="text" name="profession"  className="form-control form-control-lg form-control-solid" placeholder="Employee Type" /></> : ""} */}

            </div>
          </div>



          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">password</label>
            <div className="col-lg-8 fv-row">
              <input type="password" name="password" className="form-control form-control-lg form-control-solid" placeholder="password" />
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Confirm password</label>
            <div className="col-lg-8 fv-row">
              <input type="password" name="confirmPassword" className="form-control form-control-lg form-control-solid" placeholder="Confirm password" />
            </div>
          </div>



          <div className="row">
            <div className="col-lg-6 mb-6">

              <div className="row">
                <label class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6" for="flexSwitchCheckDefault">Is this empoly allowed to create a add customer Network?</label>
                <div class="col-lg-2 form-check form-switch  ">
                  <input class="form-check-input mt-5" type="checkbox" name="add_customer" role="switch" id="flexSwitchCheckDefault" />

                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-6">
              <div className="row">
                <label class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6" for="flexSwitchCheckDefault">Is this Empolyee allowed to approve customer?</label>
                <div class="col-lg-2 form-check form-switch  ">
                  <input class="form-check-input mt-5" type="checkbox" name="approve_customer" role="switch" id="flexSwitchCheckDefault" />

                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-6">
              <div className="row">
                <label class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6" for="flexSwitchCheckDefault">Is this Empolyee allowed to block customer.</label>
                <div class="col-lg-2 form-check form-switch  ">
                  <input class="form-check-input mt-5" type="checkbox" name="block_customer" role="switch" id="flexSwitchCheckDefault" />

                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-6">
              <div className="row">
                <label class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6" for="flexSwitchCheckDefault">Is this Empolyee allowed to  delete customer</label>
                <div class="col-lg-2 form-check form-switch  ">
                  <input class="form-check-input mt-5" type="checkbox" name="delete_customer" role="switch" id="flexSwitchCheckDefault" />

                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-6">
              <div className="row">
                <label class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6" for="flexSwitchCheckDefault"> Is this Empolyee allowed to Create digitalID</label>
                <div class="col-lg-2 form-check form-switch  ">

                  <input class="form-check-input mt-5" type="checkbox" name="createdigitalID" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">

          <button type="submit" className="btn btn-light btn-sub me-2">Submit</button>


        </div>
      </form>
    </div>


  </>
  )
}

export default ImageReader
