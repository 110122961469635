import React from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function AgentAdminAdd({hadleradmin}) {


  const navigate = useNavigate();

  let token = localStorage.getItem("ChromePayAdmitToken");
  const AddFormData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    const formData = Formvlaues;

    console.log(Formvlaues);

    axios
      .post(`/v1/admin/agentregister/6311a0de778efce58f2336db`, formData,{ headers: {"Authorization" : `Bearer ${token}`}})

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            navigate( "/agent-list");
            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };
  useEffect(()=>{
    hadleradmin();
  },[])
  return (
    <div>
      <ToastContainer />
   
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Agent</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Agent Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Full Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Password *
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="password"
                                  name="password"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="password"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6  mb-8 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Email Address
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Email Address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  mb-8 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">Contact Phone</span>
                                <i
                                  className="fas fa-exclamation-circle ms-1 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Phone number must be active"
                                ></i>
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="tel"
                                  name="phone"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Phone number"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Country
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="country"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="country"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                City
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="City"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Address
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Post Code{" "}
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  name="postCode"
                                  className="form-control form-control-lg form-control-solid position-relative"
                                  placeholder="Post Code"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Transaction Limit
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="transectionLimit"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="50000$"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                Commission Type
                              </label>

                              <div className="col-lg-8 fv-row">
                                {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                                <select
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="assetID"
                                  data-hide-search="true"
                                  name="commisionType"
                                >
                                  <option value="1" selected="selected">
                                    select commission
                                  </option>
                                  <option value="Percentage">Percentage</option>
                                  <option value="Flat Money">Flat Money</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row ">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Enter Amount
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="commissionAmount"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentAdminAdd;
