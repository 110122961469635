import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function LicensesOrganzation({hadleradmin}) {
  const navigate = useNavigate()
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [pageCount, setpageCount] = useState("");

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [catView, setCat] = useState([]);
  const [orgID2, setorgID2] = useState("");

  const limit = 10;

  const userlist = async () => {
    await axios.post(`/v1/admin/findlowLicenseOrganisattions`,{},{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
      const data = resp.data.findorg;
      setData(data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };
  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios.post(`/v1/admin/findlowLicenseOrganisattions`, senData,{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
      const data = resp.data.findorg;
      setData(data);
    });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  const onOpenModal = (orgID) => {
    setOpen(true);

    console.log(orgID);

    setorgID2(orgID);
  };

  const onCloseModal = () => setOpen(false);

  const UpdateFormData = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    //   alert(orgID2);
    axios
      .post(`/v1/admin/add_Licenses/${orgID2}`, formData, { headers: {"Authorization" : `Bearer ${token}`}})
      .then((resp) => {
        // console.log("api is work hhhhhhhhhhhhhhhhhhhhhhhh",resp.data);
        let data1 = resp.data;
        if (data1.status) {
          toast.success(data1.msg);
          setOpen(false);
        } else {
          toast.error(data1.msg);
        }
      })
      .catch((error) => console.log("api errrorllllllllllllllllllll", error));
  };
  const handleLicence = (_id) => {
    const orgID2 = _id
    // console.log("@!@!"+ orgID)
   
      Swal.fire({
        title: "Add Licenses ",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return axios
            .post(`/v1/admin/add_Licenses/${orgID2}`, { Licenses: login }, { headers: {"Authorization" : `Bearer ${token}`}} )
            .then((response) => {
              Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to Add License ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Add it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire(
                    "Add License!",
                    "Add License successfully",
                    "success"
                  );
                  // navigate( `/admin`);
                }
              });
            })
            .catch();
        },
      });

};
  return (
    <>
      
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Licences</h1>
                      <span className="fw-semibold text-white opacity-50">
                      Licences list
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                      Licences List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Image</th>
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Name</th>

                            <th className="min-w-100px">Total Licences</th>
                            {/* <th className="min-w-100px ">Status</th> */}
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              <td>
                                <img
                                  src={item.OrganisationID.logo}
                                  width="100px"
                                />
                              </td>
                              <td>{item.OrganisationID._id}</td>
                              <td>{item.name}</td>

                              <td className="text-start">
                                {item.totalLicenses}
                              </td>
                              {/* <td><span className="badge badge-light-info fs-5">{item.status}</span></td> */}
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <button
                                    onClick={(e) => {
                                      // onOpenModal(item.OrganisationID._id);
                                      handleLicence(item.OrganisationID._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Edit Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i class="fad fa-edit fs-4"></i>
                                      {/* <i className="fad fa-eye fs-4"></i> */}
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link pagestyle"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
                      <h2 className="mb-4 text-white">Update Tips & Tricks</h2>
                      <div className="mx-500">
                        <form
                          className="mt-3 w-100"
                          onSubmit={(e) => UpdateFormData(e)}
                        >
                          <div className="form-group mb-4">
                            <label className="title-col"> Organisation </label>
                           
                          </div>

                          <label className="title-col"> Add Licences</label>
                          <input
                            type="number"
                            id="Licences"
                            autoComplete="off"
                            className="form-control mb-4"
                            name="Licenses"
                          />

                          <div className="mt-3 mb-3">
                            <Button type="submit" className="mr-3 btn-pd btnBg">
                              Update
                            </Button>
                          </div>
                        </form>
                      </div>
                    </Modal>
    </>
  );
}

export default LicensesOrganzation;
