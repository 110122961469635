import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

function AdminDashboard({ hadleradmin }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [data, setData] = useState("");
  const [reccustomer, setRecCustomer] = useState("");
  const [recent, setRecent] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [recnttran, setRecntTran] = useState("");
  const [loans, setLoans] = useState();
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [frequency, setFrequency] = useState("month")
  // const logout = () => {

  //   localStorage.clear();
  //   window.location = `/`;
  // 
  // setTimeout(logout, 60 * 60 * 1000);




  const userlist = async () => {
    await axios.get(`/v1/admin/admindash`, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      let data = resp.data.dashData;
      setData(data);
      setTimeout();
    });
  };

  useEffect(() => {
    userlist();
    resentuserlist();
    loandetail();
    //UserPermoance(frequency);

    hadleradmin();
  }, []);

  const loandetail = async () => {
    await axios.post(`/v1/admin/get_all_loans`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      let loan = resp.data.find;
      setLoans(loan);
      //console.log('@@@@',loan);
    });
  };
  //    console.log(recent.totalCustomer);
  const resentuserlist = async () => {
    await axios.get(`/v1/admin/recentUser`, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      let dataresnt = resp.data.final;
      let totalCustomer = resp.data.totalCustomer;
      setRecCustomer(totalCustomer);
      setRecent(dataresnt);
    });
  };


  useEffect(() => {
    const transactionlist = async () => {
      await axios.get(`/v1/admin/recentTransection`, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
        const datalist = resp.data.data;
        const totalTransection = resp.data.totalTransection;
        setRecntTran(totalTransection);
        // setTransaction(datalist);
      });
    };
    transactionlist();
  }, []);

  const UserPermoance = () => {
    let type = frequency
    let body = { Type: type }
    axios.post(`/v1/admin/get-admin-dash-cust-graph`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
      const userData = res.data.data;
      const year = userData.map((item) => item.count)
      const month = userData.map((item) => item.value)
      console.log("++++++++++++++graph data", year, month);

      setuseractivedatayear(year);
      setuseractivedatamonths(month);
    });
  };


  useEffect(() => {
    UserPermoance();
  }, [frequency])

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    UserPermoance(sort);
    console.log(sort)

  }
  const datafilter = (e) => {

    e.preventDefault();
    const value = e.target.value;


    axios.post(`/v1/admin/get_admin_cust_data_graph`, { filter: value }, { headers: { "Authorization": `bearer ${token}` } }).then((res) => {
      var userData = res.data.obj;

      console.log("++++++++++++++graph data", userData);

      setuseractivedatayear(Object.values(userData));
      setuseractivedatamonths(Object.keys(userData));
    });
  };

  const handleView = (_id) => {
    console.log(_id);
    navigate(`/customer-view-admin/${_id}`);
    return false;
  }

  const handleTransactionView = (_id) => {
    console.log(_id);
    navigate(`/transaction-view-admin/${_id}`);
    return false;
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            Admin Dashboard
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data.totalUser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data.totalUser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Users
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-hands-usd"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-prefix="$"
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {/* {`$${data.totlaTransection}`} */}
                                    0
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data.totlaLicense}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data.totlaLicense}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total D-ID Licenses{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-hand-holding-usd"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    // data-kt-countup-value={loans}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {/* {loans} */}
    0
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Loan Applications
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div className="container card-header pt-7">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Organisation Performance
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <form >
                            <div className="d-flex align-items-center  gap-1 ">
                              <div>
                                <select defaultValue={frequency} onChange={handlesorting} className="form-select graph-select" >
                                  <option value="day"> Day</option>
                                  <option value="week"> Week</option>
                                  <option value="month"> Monthly</option>
                                  <option value="year">Yearly</option>

                                </select>
                              </div>


                            </div>
                          </form>
                        </div>

                      </div>

                      <div className="bg-white overflow-auto mt-md-5 pt-md-5">
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                              data: useractivedatayear,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 5,
                                columnWidth: 40,
                                startingShape: "flat",
                                endingShape: "rounded",
                                dataLabels: {
                                  position: "top",
                                },
                              },
                            },

                            title: {
                              //   text: "BarChar Developed by DevOps Team",
                              //   style: { fontSize: 30 },
                            },

                            subtitle: {
                              //   text: "This is BarChart Graph",
                              //   style: { fontSize: 18 },
                            },

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: useractivedatamonths,
                              //   title: {
                              //     text: "Year BY data",
                              //     style: { color: "##3c7f8c", fontSize: 10 },
                              //   },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {
                                // text: "User In (K)",
                                // style: { color: "#f90000", fontSize: 15 },
                              },
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Recent User Activity
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Over {reccustomer} Customer
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email Address</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">DOB</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {recent.map((item) => (
                            <tr>
                              <td>{item.fullname}</td>
                              <td>{item.email} </td>
                              <td>
                                <span>{item.phone} </span>
                              </td>
                              <td>
                                {moment(item.dateOfBirth).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {item.status == "pending" ?
                                  <>
                                    <span className="badge badge-light-pending text-danger  fs-5">
                                      {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                    </span>
                                  </>
                                  :
                                  <>
                                    <span className="badge badge-light-info text-success fs-5">
                                      {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                    </span>
                                  </>}

                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <a
                                    onClick={(e) => {
                                      handleView(item._id);
                                    }}
                                    // href="digital_user_detail.html"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Transactions
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Over 0 Transaction
                        {/* Over {recnttran} Transaction */}
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">
                              Transaction <br /> Date{" "}
                            </th>
                            <th className="min-w-150px">
                              PCN <br />
                              Number
                            </th>
                            <th className="min-w-150px">
                              Sender <br /> Name{" "}
                            </th>
                            <th className="min-w-100px">
                              Beneficiary <br /> Name{" "}
                            </th>
                            <th className="min-w-100px text-center">
                              Transaction <br /> Amount
                            </th>
                            {/* <th className="min-w-100px text-center">
                              Receiver <br /> Amount
                            </th> */}
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {transaction.map((item) => (
                            <tr>
                              <td>
                                {moment(item.transactionDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{item.PCN} </td>
                              <td>
                                <span>{item.senderName}</span>
                              </td>
                              <td>{item.beneficiaryName}</td>

                              <td>{item.sendingAmount}</td>
                              {/* <td>{item.receiverAmount}</td> */}
                              <td>
                                {item.status == "pending" ?
                                  <>
                                    <span className="badge badge-light-pending text-danger fs-5">
                                      {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                    </span>
                                  </>
                                  :
                                  <>
                                    <span className="badge badge-light-info text-success fs-5">
                                      {item.status?.charAt(0).toUpperCase() + item.status?.slice(1)}
                                    </span>
                                  </>
                                }

                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <button
                                    onClick={(e) => {
                                      handleTransactionView(item._id);
                                    }}

                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminDashboard;
