import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";
import moment from "moment";
var jwt = require("jsonwebtoken");

const SuperAgentReport = ({ hadleradmin }) => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [pageCount, setpageCount] = useState("");
    const [orgmenu, setOrgMenu] = useState([]);
    const [open, setOpen] = useState(false);
    const [orgID2, setorgID2] = useState("");
    const [total, settotal] = useState("");
    const [listorg, setlistOrg] = useState([]);

    const limit = 10;
    let token = localStorage.getItem("ChromePayAdmitToken");
    var decode1 = jwt.decode(token);
    let adminID = decode1.admminID;
    const AddFormData = async (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        console.log(Formvlaues);
        axios.post(`/v1/admin/super-agent-list`, formData, { headers: { "Authorization": `bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            // console.log("@@@",data)
            setData(data);
            const total = resp.data.totalRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };

    const userlist = async () => {
        await axios.post(`/v1/admin/super-agent-list`, {}, { headers: { "Authorization": `bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
            console.log("####" + data);
            const total = resp.data.totalRow;
            console.log(total);
            settotal(total)
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
        });
    };
    const orgList = async () => {
        await axios.get(`/v1/admin/orgList`).then((resp) => {
            let data = resp.data.Org;
            setlistOrg(data);
            console.log(data);
        });
    };
    useEffect(() => {
        userlist();
        hadleradmin();
        orgList();
    }, []);

    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
        const senData = { page: page };
        axios.post(`/v1/admin/super-agent-list`, senData, { headers: { "Authorization": `bearer ${token}` } }).then((resp) => {
            const data = resp.data.data;
            setData(data);
        });
        return data;
    };

    const handlePageClick = async (data) => {
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setData(commentsFormServer);
    };

    /////////////unblocked user api call ////////////
    const handleunblocked = (_id) => {
        console.log(_id);
        axios.put(`/v1/admin/unsuspend/${_id}`).then((res) => {
            if (res.status) {
                let data = res.data;
                toast.success(data.msg);
                return userlist();
            }
        });
    };

    /////////////blocked user api call ////////////
    // const handlesuspend = (_id) =>
    // {
    // axios.put(`/suspend/${_id}`)
    // .then(res => {
    //     if (res.status) {
    //         let data = res.data;
    //           toast.success(data.msg);
    //           return  userlist();
    //         }
    // })
    // }
    /////////////Delete orgnization api Call///////

    /////////////////delete api call /////////////////
    const handlesuspend = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to Suspend selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Suspend it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`/v1/admin/suspend/${_id}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire("Suspend!", "Customer Suspend successfully", "success");
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };
    // const handledelete =(_id) =>
    // {

    // axios.delete(`/deleteOrganization/${_id}`)
    // .then(res =>{
    //     if(res.status){
    //         let data = res.data;
    //         toast.success(data.msg);
    //         return userlist();
    //     }
    // })
    // }
    const handledelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/v1/admin/deleteOrganization/${_id}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire("Deleted!", "Customer deleted successfully", "success");
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

    const handleView = (_id) => {
        // console.log(_id)
        navigate(`/admin/super-agent-view/${_id}`);
        return false;
    };
    const handleVerfiy = (_id) => {
        const { orgID } = { orgID: _id };
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to verify selected Customer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, verify it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`/v1/admin/orgVerify/${orgID}/${adminID}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire(
                                "verified!",
                                "Organisation         verified successfully",
                                "success"
                            );
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

    const handleUserView = (_id) => {
        console.log(_id);
        navigate(`/user-customer-view/${_id}`);
        return false;
    };

    // add admin license

    const Addlicense = (_id) => {
        console.log(_id);
    };

    // add document function
    const Adddocument = () => {
        alert("hello");
    };

    const subAdminlist = async () => {
        await axios.post(`/v1/admin/subAdminRole/${adminID}`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
            let data = resp.data.find;
            // console.log('@@@@@',data.customer.addCustomer)
            // setMenu(data.customer);
            setOrgMenu(data.Organisation);
            // setAgent(data.Agent)
            // setIP(data.IP)
        });
    };

    useEffect(() => {
        subAdminlist();
    }, []);

    const onOpenModal = (orgID) => {
        setOpen(true);

        console.log(orgID);

        setorgID2(orgID);
    };

    const onCloseModal = () => setOpen(false);

    const UpdateFormData = async (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;

        //   alert(orgID2);
        axios
            .post(`/v1/admin/add_Licenses/${orgID2}`, formData)
            .then((resp) => {
                // console.log("api is work hhhhhhhhhhhhhhhhhhhhhhhh",resp.data);
                let data1 = resp.data;
                if (data1.status) {
                    toast.success(data1.msg);
                    setOpen(false);

                    // let data = resp.data.update
                    // if(data.status){
                    //     toast.success(data.msg);
                    //     setOpen(false);

                    // }else{
                    //     toast.error(data.msg);
                    // }
                } else {
                    toast.error(data1.msg);
                }
            })
            .catch((error) => console.log("api errrorllllllllllllllllllll", error));
    };

    console.log("Iddddddddddddddddddddddddddd", data)

    // Update License //

    const handleAddLicense = (_id) => {
        const { orgID } = { orgID: _id }
        Swal.fire({
            title: "Add Licenses ",
            input: "number",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return axios
                    .post(`/v1/admin/add_Licenses/${orgID}`, { Licenses: login }, { headers: { "Authorization": `Bearer ${token}` } })
                    .then((response) => {
                        Swal.fire({
                            title: "Are you sure?",
                            text: "Are you sure you want to Add License ",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Add it!",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.fire(
                                    "Add License!",
                                    "Add License   successfully",
                                    "success"
                                );
                                userlist();
                            }
                        });
                    })
                    .catch();
            },
        });

    };

    const handleReport = (_id) =>{
        navigate(`/admin/super-agent-perfomance/${_id}`);
    }
    return (
        <div>
            <ToastContainer />

            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div
                        className="wrapper d-flex flex-column flex-row-fluid"
                        id="kt_wrapper"
                    >
                        <div
                            className="content d-flex flex-column flex-column-fluid"
                            id="kt_content"
                        >
                            <div className="container-xxl" id="kt_content_container">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-lg-12">
                                        {/* style="background-color: #2c486d;background-size: auto 100%; background-image: url(assets_new/images/taieri.svg)" */}
                                        <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                                            <div className="card-body container-xxl pt-10 pb-8">
                                                <div className="d-flex align-items-center">
                                                    <h1 className="fw-semibold me-3 text-white">
                                                        Filter
                                                    </h1>
                                                    <span className="fw-semibold text-white opacity-50">
                                                        Super Agent Report List
                                                    </span>
                                                </div>

                                                <div className="d-flex flex-column">
                                                    <div className=" align-lg-items-center">
                                                        <form onSubmit={(e) => AddFormData(e)}>
                                                            <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body  me-lg-10 my-5">
                                                                <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                                                    {/* <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="2"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="2"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <select
                                      className="form-select border-0 flex-grow-1"
                                      data-control="select2"
                                      data-placeholder="Status"
                                      data-hide-search="true"
                                      name="status"
                                    >
                                      <option value=""></option>
                                      <option value="1" selected="selected">
                                        Category
                                      </option>
                                      <option value="pending">Pending</option>
                                      <option value="Confirmed">
                                        Confirmed
                                      </option>
                                      <option value="verified">verified</option>
                                    </select>
                                  </div> */}
                                                                    <div className="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                                                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect
                                                                                    opacity="0.5"
                                                                                    x="17.0365"
                                                                                    y="15.1223"
                                                                                    width="8.15546"
                                                                                    height="2"
                                                                                    rx="1"
                                                                                    transform="rotate(45 17.0365 15.1223)"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <path
                                                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-flush flex-grow-1"
                                                                            name="AgentCode"
                                                                            placeholder="Enter Agent Code"
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect
                                                                                    x="2"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="2"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <select
                                                                            className="form-select border-0 flex-grow-1"
                                                                            data-control="select2"
                                                                            data-placeholder="Organisation Type"
                                                                            data-hide-search="true"
                                                                            name="organization"
                                                                        >
                                                                            <option value="1"> Select Organisation</option>
                                                                            {listorg.map((item) => (
                                                                                <option key={item._id} value={item._id}>
                                                                                    {item.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                    </div>
                                                                    {/* <div className="col-lg-3 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                        <label className="small_label">
                                                                            Start Date
                                                                        </label>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <i className="fad fa-calendar fs-2"></i>
                                                                        </span>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                                            name="fromDate"
                                                                            placeholder="User Name.."
                                                                        />
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                    </div>

                                                                    <div className="col-lg-3 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                        <label className="small_label">
                                                                            End Date
                                                                        </label>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <i className="fad fa-calendar fs-2"></i>
                                                                        </span>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                                            name="toDate"
                                                                            placeholder="User Name.."
                                                                        />
                                                                    </div> */}


                                                                </div>

                                                                <div className="min-w-150px text-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        id="kt_advanced_search_button_1"
                                                                    >
                                                                        Search
                                                                    </button>
                                                                    <button
                                                                        type="reset"
                                                                        onClick={userlist}
                                                                        className="btn btn-secondary"
                                                                        id="kt_advanced_search_button_1"
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                            <div className="card-header border-0 pt-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                        Super Agent  Report List
                                                    </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7">
                                                        Over {total} Super Agent{" "}
                                                    </span>
                                                </h3>
                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

<thead>
    <tr className="fw-bold text-muted th-title">


        <th className="min-w-125px">Image</th>
        <th className="min-w-125px">First Name</th>
        <th className="min-w-125px">Last Name</th>
        <th className="min-w-150px">Email</th>
        <th className="min-w-150px">Contact No. </th>
        <th className="min-w-100px">Agent Code</th>
        <th className="min-w-100px">Organisation</th>
        <th className="min-w-100px">Status</th>
        {/* <th className="min-w-100px">City</th> */}
        {/* <th className="min-w-100px ">postCode</th> */}
        {/* <th className="min-w-100px ">Transection Limit</th> */}
        <th className="min-w-100px text-end">Actions</th>
    </tr>
</thead>

<tbody>
     { data.map((item) => 
    <tr>
        <td><img src={item.Image} alt="profile" width="80px" height="80px"/></td>
        <td> {item.firstName} </td>
        <td> {item.lastName} </td>
        <td> {item.email} </td>
        <td><span>{item.phone}</span> </td>
        <td>{item.AgentCode}</td>
        <td>{item.org_detail?.name}</td>
        {/* <td>{item.country}</td> */}
        {/* <td>{item.city}</td> */}
        <td>
{item.status == "1" ? (
<>
<span class="badge badge-light-pending fs-5">
{/* {item.status} */}
Inactive
</span>
</>
) : (
<>
<span class="badge badge-light-info  fs-5">
{/* {item.status} */}
Active
</span>
</>
)}
</td>
        {/* <td><span class="badge badge-light-info fs-5">{item.postCode}</span></td> */}
        {/* <td><span class="badge badge-light-info fs-5">{item.transectionLimit}</span></td> */}
        <td>
            <div className="d-flex justify-content-end flex-shrink-0">
                {/* { item.isBlocked == '1'? 
                <>
                <button onClick={(e) => { handleunblocked(item.isBlocked ="0",item._id);}}
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Suspend ">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/blocked.png" width="20px" />
                    </span>
                </button>
                </>:
                <>
                 <button onClick={(e) => { handleunblocked(item.isBlocked="1",item._id);}}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Block">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/suspand.png" width="20px" />
                    </span>
                </button>
                </>
                } */}
                
               
                {/* <button onClick={(e)=>{handleReportagent(item._id)}}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Report">
                    <span className="svg-icon svg-icon-3">
                
                    <i class="fad fa-file-chart-line fs-1" ></i>
                       
                    </span>

                </button> */}
                 <button
                                    onClick={(e) => {
                                      handleReport(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Report"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      {/* <i className="fad fa-eye fs-4"></i> */}

                                      <img
                                        src="/assets_new/images/report.png"
                                        width="35px"
                                      />
                                    </span>
                                  </button>
                <button onClick={(e) => handleView(item._id)}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="View Detail">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye fs-4"></i>
                    </span>

                </button>
                {/* <button onClick={(e)=>handleAddLoan(item._id)}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Add Loan ">
                    <span className="svg-icon svg-icon-3">
                        <i class="fad fa-sack-dollar fs-1"></i>
                    </span>
                </button> */}
                {/* <button onClick={(e) => { handledelete(item._id)}}
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Delete">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/cross.png" width="20px" />
                    </span>

                </button> */}
                {/* <a href='#'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Disable OTP">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye-slash"></i>
                    </span>

                </a> */}
            </div>
        </td>
    </tr>
     )}
    {/* <tr>
        <td>##41e8bd3f3ca</td>
        <td> Virendra Shilpkar </td>
        <td> virendra@gmail.com </td>
        <td><span>+221 123-456-7840</span> </td>
        <td>05/06/1980</td>
        <td>05/08/2020</td>
        <td>Ethiopia</td>
        <td>
        <td><span class="badge badge-light-info fs-5">Verified</span></td> 
        </td>
        <td>
            <div className="d-flex justify-content-end flex-shrink-0">
                <a href="#"
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Suspend ">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-minus-circle fs-4"></i>
                    </span>
                </a>
                <a href="#"
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Password Reset ">
                    <span className="svg-icon svg-icon-3">
                        <i className="fas fa-repeat-alt"></i>
                    </span>
                </a>
                <a href='organization_detail.html'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="View Detail">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye fs-4"></i>
                    </span>

                </a>
                <a href='#'
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Delete">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-trash-alt"></i>
                    </span>

                </a>
                <a href='#'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Disable OTP">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye-slash"></i>
                    </span>

                </a>
            </div>
        </td>
    </tr> */}


</tbody>

</table>
                                                </div>
                                                <div className="col-lg-12 mt-2 text-end">
                                                    <ReactPaginate
                                                        previousLabel={"previous"}
                                                        nextLabel={"next"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={
                                                            "pagination justify-content-end"
                                                        }
                                                        pageClassName={"page-item mx-4"}
                                                        pageLinkClassName={"page-link"}
                                                        previousClassName={"page-item mx-2"}
                                                        previousLinkClassName={"page-link pagestyle"}
                                                        nextClassName={"page-item"}
                                                        nextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal  open={open} onClose={onCloseModal} center>
                          <h2 className="mb-4  text-white">
                            Update Tips & Tricks
                          </h2>
                          <div className="mx-500">
                            <form
                              className="mt-3 w-100"
                              onSubmit={(e) => UpdateFormData(e)}
                            >
                              <div className="form-group mb-4">
                                <label className="title-col">
                                  {" "}
                                  Organisation{" "}
                                </label>
                              
                              </div>

                              <label className="title-col"> Add Licenses</label>
                              <input
                                type="number"
                                id="Licenses"
                                autoComplete="off"
                                className="form-control mb-4"
                                name="Licenses"
                              />

                              <div className="mt-3 mb-3">
                                <Button
                                  type="submit"
                                  className="mr-3 btn-pd btnBg"
                                >
                                  Update
                                </Button>
                              </div>
                            </form>
                          </div>
                        </Modal> */}

        </div>
    );
};






export default SuperAgentReport